import * as React from 'react';
import { graphql } from 'gatsby';
import { Box, Button, Container, Stack } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/careers.scss';

const Career = ({ data }) => {
  const { careers, featuredImage, title } = data.wpCareer;
  const image = getImage(featuredImage.node.localFile);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let url = careers.formLinkUrl;
  if (emailRegex.test(careers.formLinkUrl)) {
    url = `mailto:${url}`;
  }

  return (
    <Layout>
      <SEO
        title="About the Ostara Company Pioneering Sustainable Nutrient Solutions | Ostara"
        description="Learn how Ostara is pioneering sustainable phosphate management with innovative technology and enhanced-efficiency fertilizers for data-driven growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Ostara, Company"
      />
      <main className="careers">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>{title}</h1>
                  <p style={{ maxWidth: 550 }}>
                    {careers.subtitle.replace(/(<([^>]+)>)/gi, '')}
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <GatsbyImage className="hero-img" image={image} alt={title} />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Container
          paddingX={8}
          paddingBottom={8}
          maxW={764}
          className="careers-content__container"
        >
          <Box className="careers-content__description">
            {parse(careers.mainContent)}
          </Box>
          <Box marginTop={12}>
            <a href={url} target="_blank" rel="noreferrer noopener">
              <Button
                className="custom-button"
                fontSize="sm"
                fontWeight={600}
                color="#044606"
                bg="#7fba00"
                _hover={{
                  bg: '#044606',
                  color: 'white',
                }}
                rightIcon={<ChevronRightIcon w={8} h={8} />}
              >
                <h5>Apply Now</h5>
              </Button>
            </a>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Career;

export const CareerQuery = graphql`
  query ($id: String) {
    wpCareer(id: { eq: $id }) {
      title
      slug
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      careers {
        subtitle
        mainContent
        formLinkUrl
      }
    }
  }
`;
